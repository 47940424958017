import { EnumToArray } from '../../../utils/enumToArray';

enum StepperType {
  Default = 'default',
  Recurrence = 'recurrence',
  Split = 'split'
}
export const PTZStepperConfigList = {
  StepperTypeList: EnumToArray([StepperType]),
};
export const PTZStepperConfig = {
  StepperType,
};
