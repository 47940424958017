import { Component, Host, Method, Prop, State, Watch, h } from '@stencil/core';
import { PTZStepperConfig } from './types/ptz-stepper.enums';
import { PTZStepperTypes, Step } from './types/ptz-stepper.types';

@Component({
  tag: `ptz-stepper`,
  styleUrl: 'ptz-stepper.scss',
  shadow: false,
})
export class PTZStepper {
  /**Propriedade para controlar a lista de steps */
  @Prop() steps: Step[] | string;

  /**Propriedade para controlar o estilo do estado do step quando for Done */
  @Prop() useCheckedState?: boolean = false;

  /**Propriedade para controlar a direação do stepper */
  @Prop() isVertical?: boolean = true;

  /**Propriedade para controlar a direação do stepper */
  @Prop() isVerticalLeft?: boolean = false;

  /**Propriedade para controlar se deve aparecer os botoes */
  @Prop() showButtons?: boolean = true;

  /**Propriedade para controlar o estado do botão direito */
  @Prop() disableButtonRight?: boolean = false;

  /**Propriedade para controlar o estado do botão esquerdo */
  @Prop() disableButtonLeft?: boolean = false;

  /**Propriedade para controlar o tipo do stepper */
  @Prop() stepperType: PTZStepperTypes.StepperType = PTZStepperConfig.StepperType.Default;

  /**Estado para controlar o step atual */
  @State() current: number = 0;

  /**Estado para controlar o estilo do estado do step quando for Done */
  @State() isChecked: boolean = false;

  private _steps: Step[];

  @Watch('steps')
  arrayDataWatcher(newValue: Step[] | string) {
    if (typeof newValue === 'string') {
      this._steps = JSON.parse(newValue);
    } else {
      this._steps = newValue;
    }
  }

  componentWillLoad() {
    this.arrayDataWatcher(this.steps);
  }

  componentDidLoad() {
    this.isChecked = this.useCheckedState;
  }

  @Method()
  async callHandleNext() {
    this.handleNext();
  }

  @Method()
  async callHandlePrevious() {
    this.handlePrevious();
  }

  private handleNext = () => {
    this.current = Math.min(this.current + 1, this._steps.length);
  };

  private handlePrevious = () => {
    this.current = Math.max(this.current - 1, 0);
  };

  render() {
    const checked = this.isChecked;
    const showButtons = this.showButtons;
    const disableBtnRight = this.disableButtonRight;
    const disableBtnLeft = this.disableButtonLeft;
    const handlePreviousStep = () => this.handlePrevious;
    const handleNextStep = () => this.handleNext;
    const recurrence = PTZStepperConfig.StepperType.Recurrence;

    function StepContent({ done }) {
      return done ? <ptz-icon name="check-solid" variant="solid" size="md" color="neutral-white" /> : '';
    }

    function ButtonRight({ current, steps, isVertical }) {
      return (
        <button onClick={handleNextStep()} disabled={current === steps.length || disableBtnRight}>
          <ptz-icon name={isVertical ? 'angle-down' : 'angle-right'} variant="line" size="lg" color="neutral-darker-accent" />
        </button>
      );
    }

    function ButtonLeft({ current, isVertical }) {
      return (
        <button onClick={handlePreviousStep()} disabled={current === 0 || disableBtnLeft}>
          <ptz-icon name={isVertical ? 'angle-up' : 'angle-left'} variant="line" size="lg" color="neutral-darker-accent" />
        </button>
      );
    }

    function renderStep({ title, description }, key) {
      const { current } = this;
      const isVertical = this.isVertical;
      const type = this.type;
      const done = key < current;
      const currentStep = key === current;
      const currentClassName = currentStep ? ` ptz-stepper-step__current` : '';
      const doneClassName = done ? ` ptz-stepper-step__done` : '';
      const className = `ptz-stepper-step${currentClassName}${doneClassName} ${type}`;

      return (
        <li class={` ${className} ${description ? '' : 'no-description'}`} key={key}>
          {checked && done && (
            <div class={`ptz-stepper-step__index`}>
              <StepContent done={done} />
            </div>
          )}
          {isVertical && <div class={`ptz-stepper-step__steps ${className}`}></div>}
          <div class={`ptz-stepper-step__title`}>
            <p class={`ptz-stepper-step__label`}>{title}</p>
            <p class={`ptz-stepper-step__description`}>{description}</p>
          </div>
        </li>
      );
    }

    function HorizontalStepper({ current, steps, stepperType }) {
      const isVertical = false;
      const type = stepperType;
      return (
        <div class="horizontal-wrapper">
          {showButtons && stepperType !== recurrence && <ButtonLeft current={current} isVertical={false} />}
          <ul class="ptz-stepper">
            {steps.map(renderStep, { current, isVertical, type })}
            {stepperType === recurrence && (
              <li class="ptz-stepper-step recurrence">
                <div class="icon-recurrence">
                  <ptz-icon name="angle-right" variant="line" size="md" color="neutral-mid" />
                </div>
              </li>
            )}
          </ul>
          {showButtons && stepperType !== recurrence && <ButtonRight current={current} steps={steps} isVertical={false} />}
        </div>
      );
    }

    function VerticalStepper({ current, steps, stepperType }) {
      const isVertical = true;
      const type = stepperType;
      return (
        <div class="vertical-wrapper">
          <ul class="ptz-stepper">
            {steps.map(renderStep, { current, isVertical, type })}
            {stepperType === recurrence && (
              <div class="icon-recurrence">
                <ptz-icon name="angle-down" variant="line" size="md" color="neutral-mid" />
              </div>
            )}
          </ul>
          {showButtons && stepperType !== recurrence && (
            <div class="ptz-stepper-btn-wrapper">
              <ButtonLeft current={current} isVertical={true} />
              <ButtonRight current={current} steps={steps} isVertical={true} />
            </div>
          )}
        </div>
      );
    }
    return (
      <Host>
        {this.isVertical ? (
          <VerticalStepper steps={this._steps} current={this.current} stepperType={this.stepperType} />
        ) : (
          <HorizontalStepper steps={this._steps} current={this.current} stepperType={this.stepperType} />
        )}
      </Host>
    );
  }
}
