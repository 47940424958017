ptz-stepper[is-vertical-left='true'] {
  .vertical-wrapper {
    left: $petz-spacing-xs;
    .ptz-stepper {
      .icon-recurrence {
        left: -10.7px;
        right: auto;
      }
      &-step__steps {
        order: 0;
      }
      &-step__title {
        text-align: start;
      }
      &-step__index {
        left: -$petz-spacing-xs;
      }
    }
    .no-description {
      .ptz-stepper-step__index {
        left: -$petz-spacing-xs;
      }
    }
  }
}

ptz-stepper[is-vertical-left='false'] {
  .ptz-stepper-btn-wrapper {
    justify-content: end;
  }
  .ptz-stepper {
    &-step__title {
      justify-items: end;
    }
  }
}

ptz-stepper {
  width: 100%;
  position: absolute;

  button {
    display: flex;
    width: $petz-spacing-lg;
    height: $petz-spacing-lg;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: $petz-rounded-max;
    border: 1px solid $petz-color-neutral-light;
    background: $petz-color-neutral-white;
    cursor: pointer;
    ptz-icon {
      display: flex;
    }
  }
  button[disabled] {
    background: $petz-color-neutral-lighter-accent;
    border: $petz-color-neutral-mid;
    i {
      color: $petz-color-neutral-mid;
    }
  }
  p {
    margin: 0;
    height: 14px;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  .horizontal-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    .ptz-stepper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      width: 80%;

      &-step {
        list-style-type: none;
        float: left;
        width: calc(100% - $petz-spacing-6xl);
        position: relative;
        text-align: center;
        display: grid;
        gap: $petz-spacing-xs;
        justify-items: center;
        align-content: space-between;

        &::before {
          content: ' ';
          border-radius: 50%;
          width: $petz-spacing-2xs;
          height: $petz-spacing-2xs;
          display: block;
          text-align: center;
          margin: 0 auto 10px;
          background-color: $petz-color-neutral-mid;
        }

        &::after {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: $petz-color-neutral-mid;
          top: 3px;
          left: -50%;
          z-index: -1;
        }

        &:first-child::after {
          content: none;
        }

        &__title {
          display: grid;
          gap: $petz-spacing-4xs;
          justify-items: center;
        }

        &__label {
          color: $petz-color-neutral-black;
          text-align: center;
          font-size: $petz-font-size-2xs;
          font-weight: $petz-font-weight-bold;
          display: block;
          max-width: 120px;
          word-wrap: break-word;
        }

        &__description {
          color: $petz-color-neutral-dark;
          font-size: $petz-font-size-3xs;
          font-weight: $petz-font-weight-regular;
          display: block;
          max-width: 60px;
          word-wrap: break-word;
        }

        &__index {
          width: $petz-spacing-md;
          height: $petz-spacing-md;
          border-radius: 50%;
          background: $petz-color-sup-green-dark;
          display: flex;
          align-items: center;
          justify-content: center;
          top: -$petz-spacing-2xs;
          z-index: 9;
          position: absolute;
          i {
            display: flex;
          }
        }

        &__current &__index {
          color: $petz-color-sup-green-dark;
        }

        &__current {
          &::before {
            background-color: $petz-color-sup-green-dark;
            box-shadow: 0 0 0px 6px rgba(52, 173, 81, 0.16), 0 0 0px 12px rgba(52, 173, 81, 0.08);
            border: none;
            width: 14px;
            height: 14px;
            bottom: 3px;
            position: relative;
          }

          &::after {
            background-color: $petz-color-sup-green-dark;
          }
        }

        &__done:before {
          background: $petz-color-sup-green-dark;
          border: none;
          width: 14px;
          height: 14px;
          bottom: 3px;
          position: relative;
        }
        &__done:after {
          background: $petz-color-sup-green-dark;
        }
      }

      .recurrence {
        &:nth-last-child(1) {
          &::before {
            display: none;
          }
          .icon-recurrence {
            position: relative;
            top: -6.5px;
          }
        }
        &::before {
          box-shadow: none;
        }
        &:first-child {
          &:before {
            background: $petz-color-sup-red-dark;
          }
        }
        &:nth-child(2) {
          &::before {
            background: $petz-color-sup-green-dark;
            border: none;
            width: 14px;
            height: 14px;
            bottom: 3px;
            position: relative;
          }
          &::after {
            border-style: dashed;
            border-bottom: none;
            border-width: 1px;
            background-color: $petz-color-neutral-white;
            border-color: $petz-color-neutral-mid;
          }
        }
      }
      .split {
        &:last-child {
          &::after {
            width: 40%;
            left: 10%;
          }
        }
        &:first-child {
          &::after {
            content: '';
            position: absolute;
            width: 40%;
            height: 1px;
            background-color: $petz-color-neutral-mid;
            top: 3px;
            left: 50%;
            z-index: -1;
          }
        }
        &.ptz-stepper-step__done {
          &:first-child {
            &::after {
              background-color: $petz-color-sup-green-dark;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .ptz-stepper-btn-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1rem;
    position: relative;
    left: 5px;
    i {
      display: flex;
    }
  }

  .vertical-wrapper {
    display: grid;
    gap: $petz-spacing-2xl;
    position: absolute;

    .icon-recurrence {
      position: absolute;
      bottom: -22px;
      right: -9.3px;
    }

    .ptz-stepper {
      .no-description {
        .ptz-stepper-step__steps {
          &::after {
            top: 7px;
          }
        }
        .ptz-stepper-step__current,
        .ptz-stepper-step__done {
          &::after {
            top: 3px;
          }
        }
        .ptz-stepper-step__index {
          top: -2px;
        }
        .icon-recurrence {
          bottom: 3px;
          left: 40.6px;
        }
        &:nth-child(2) {
          .recurrence {
            &:after {
              top: 3px;
            }
          }
        }
      }

      &-step {
        position: relative;
        display: flex;
        gap: $petz-gap-sm;
        list-style-type: none;

        &:last-child {
          .ptz-stepper-step__steps {
            &::before {
              display: none;
            }
          }
          .ptz-stepper-step__title {
            padding-bottom: 0rem;
          }
          .split {
            &.ptz-stepper-step__current {
              &::before {
                background-color: $petz-color-sup-green-dark;
              }
            }
          }
        }

        &__steps {
          order: 1;

          &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background-color: $petz-color-neutral-mid;
            top: 14px;
            right: 100%;
            z-index: -1;
          }

          &::after {
            content: '';
            position: absolute;
            left: calc(0px - 0.25rem);
            top: 14px;
            z-index: 1;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            background-color: $petz-color-neutral-mid;
          }
        }

        &__title {
          text-align: end;
          padding-bottom: $petz-spacing-4xl;
          display: grid;
          gap: 2px;
        }

        &__label {
          color: $petz-color-neutral-black;
          font-size: $petz-font-size-2xs;
          font-weight: $petz-font-weight-bold;
          display: block;
          width: 120px;
          word-wrap: break-word;
        }

        &__description {
          color: $petz-color-neutral-dark;
          font-size: $petz-font-size-3xs;
          font-weight: $petz-font-weight-regular;
          display: block;
          width: 60px;
          word-wrap: break-word;
        }

        &__index {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: $petz-color-sup-green-dark;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 5px;
          right: -12px;
          z-index: 9;
          i {
            display: flex;
          }
        }

        &__current &__index {
          color: $petz-color-sup-green-dark;
        }

        &__current:after {
          background-color: $petz-color-sup-green-dark;
          box-shadow: 0 0 0px 6px rgba(52, 173, 81, 0.16), 0 0 0px 12px rgba(52, 173, 81, 0.08);
          border: none;
          width: 14px;
          height: 14px;
          top: 10px;
          position: absolute;
          left: -8px;
        }

        &__done {
          &::after {
            background: $petz-color-sup-green-dark;
            border: none;
            width: 14px;
            height: 14px;
            top: 10px;
            position: absolute;
            left: -8px;
          }

          &::before {
            background: $petz-color-sup-green-dark;
          }
        }

        &:first-child {
          .recurrence {
            &:after {
              background: $petz-color-sup-red-dark;
            }
            &:before {
              border-style: dashed;
              border-left: none;
              border-width: 1px;
              background-color: $petz-color-neutral-white;
              border-color: $petz-color-neutral-mid;
            }
          }
          .split {
            &::before {
              height: 40px;
              top: 12px;
            }
            &.ptz-stepper-step__done {
              &::before {
                height: 100%;
              }
            }
          }
        }
        &:nth-child(2) {
          .recurrence {
            &:after {
              background: $petz-color-sup-green-dark;
              border: none;
              width: 14px;
              height: 14px;
              top: 10px;
              position: absolute;
              left: -7.5px;
            }
          }
        }
        &:last-child {
          .recurrence {
            &:before {
              display: block;
              height: 100px;
            }
          }
          .split {
            &:before {
              height: 40px;
              top: -25px;
              display: block;
            }
          }
        }
        .recurrence {
          &:after {
            box-shadow: none;
          }
        }
      }
    }
  }
}
